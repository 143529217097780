<template>
  <div class="card">
    <ActionColaboradorModal
      itemIdentifier="usuário"
      :modalShow="modalCpf.showModal"
      :typeModal="modalCpf.typeModal"
      :infoText="modalCpf.infoText"
      @confirmed="closeModalCpf"
    />
    <overlay
      :show="loading"
      class-name="p-card-form"
    >
      <validation-observer ref="formItems">
        <b-form>
          <b-row>
            <!-- CPF -->
            <b-col
              lg="4"
              md="6"
              sm="12"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="CPF"
                rules="required|cpf"
              >
                <b-form-group
                  label="CPF *"
                  label-for="cpf-input"
                >
                  <b-form-input
                    id="cpf"
                    v-model="getFormData.cpf"
                    v-mask="'###.###.###-##'"
                    placeholder="000.000.000-00"
                    autocomplete="off"
                    @input="returnPessoa"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Nome -->
            <b-col
              lg="8"
              md="6"
              sm="12"
              class="mb-2"
            >
              <b-form-group
                label="Nome completo *"
                label-for="nome-input"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nome"
                  rules="required"
                >
                  <b-form-input
                    id="nome-input"
                    v-model="getFormData.nome"
                    autocomplete="off"
                    placeholder="Nome completo"
                    :disabled="formDisabled"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- E-mail -->
            <b-col
              lg="4"
              md="6"
              sm="12"
              class="mb-2"
            >
              <b-form-group
                label="E-mail *"
                label-for="email-input"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email-input"
                    v-model="getFormData.email"
                    autocomplete="off"
                    placeholder="Email"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Sexo -->
            <b-col
              sm="6"
              lg="4"
              class="mb-2"
            >
              <b-form-group
                label="Sexo*"
                label-for="sexo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Sexo"
                  rules="required"
                >
                  <v-select
                    id="sexo"
                    v-model="getFormData.sexo"
                    :options="sex"
                    variant="custom"
                    item-text="name"
                    item-value="name"
                    placeholder="Selecione uma opção"
                    label="name"
                  >
                    <template
                      v-slot:no-options
                    >
                      Nenhum registro encontrado.
                    </template>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Data de nascimento -->
            <b-col
              sm="6"
              lg="4"
              class="mb-2"
            >
              <b-form-group
                label="Data de nascimento*"
                label-for="nascimento"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Data de nascimento"
                  rules="required|nascimento"
                >
                  <cleave
                    id="nascimento"
                    v-model="getFormData.nascimento"
                    class="form-control"
                    :raw="false"
                    :options="options.date"
                    autocomplete="off"
                    placeholder="00/00/0000"
                    :disabled="formDisabled"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Perfil, Departamento, Regiões, Unidades, Base Nacional -->
          <sesi-profile-settings
            ref="profileSettings"
            :mode="getMode"
            @setLoading="setLoading"
          />

          <table-companies
            v-if="showTableCompanies"
            ref="tableCompanies"
            :username="getFormData.nome"
            @setCompanies="setCompanies"
          />

          <b-row>
            <b-col
              cols="12"
              class="mt-3"
            >
              <button
                type="button"
                class="btn custom-blue button-custom-size"
                @click="formSubmit(true)"
              >
                <feather-icon icon="CheckIcon" />
                Salvar usuário
              </button>

              <button
                v-if="getMode === 'insert'"
                type="button"
                class="btn custom-blue button-custom-size"
                @click="formSubmit(false)"
              >
                <feather-icon icon="CheckIcon" />
                Salvar e cadastrar novo
              </button>

              <button
                type="button"
                class="btn btn-outline-danger button-custom-size"
                @click="cancel"
              >
                <feather-icon icon="XIcon" />
                Cancelar
              </button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </overlay>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, cpf, nascimento,
} from '@validations'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import vSelect from 'vue-select'
import { getArrayAttr, strClear } from '@core/utils/utils'
import {
  successMessage,
  warningInfoMessage,
  warningMessage,
  warningMessageChangeProfileCase2,
} from '@/libs/sweetalerts'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import chavePerfil from '@/enum/chavePerfil'
import SesiProfileSettings from '@/views/custom-pages/gerenciarUsuarios/components/SesiProfileSettings.vue'
import {
  createAdminSesiUser,
  updateAdminSesiUser,
} from '@/views/custom-pages/gerenciarUsuarios/requests'
import TableCompanies from '@/views/custom-pages/gerenciarUsuarios/components/TableCompanies.vue'
import { actions, subjects } from '@/libs/acl/rules'
import ActionColaboradorModal from "@/views/components/custom/modals/ActionsColaboradorModal.vue";

export default {
  components: {
    TableCompanies,
    SesiProfileSettings,
    vSelect,
    Overlay,
    Cleave,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    ActionColaboradorModal,
  },

  props: {
    mode: {
      type: String,
      default: 'insert',
    },
  },

  data() {
    return {
      required,
      email,
      cpf,
      nascimento,

      loading: false,

      moment,

      titlePage: '',

      redirect: false,

      sex: [
        { name: 'Masculino' },
        { name: 'Feminino' },
      ],

      options: {
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
      },

      regions: [],
      chooseRegions: [],
      formDisabled: false,
      modalCpf: {
        showModal: false,
        typeModal: "custom-blue",
        infoText: "",
      },
    }
  },

  computed: {
    getMode() {
      return this.mode
    },

    getFormData() {
      return this.$store.getters['adminUserState/getFormData']
    },

    getAdminUserUpdate() {
      return this.$store.getters['adminUserState/getAdminUserUpdate']
    },

    getProfileKey() {
      return this.getFormData.perfil ? this.getFormData.perfil.chave : null
    },

    getUoProfileMode() {
      return [
        chavePerfil.SAUDE_REGIAO,
        chavePerfil.ADMIN_UNIDADE,
        chavePerfil.VACINADOR_PADRAO,
        chavePerfil.VACINADOR_ADMIN,
        chavePerfil.SAUDE_UO,
      ].includes(this.getProfileKey)
    },

    hasProfileChange() {
      return (this.getProfileKey && this.getProfileKey === chavePerfil.ADMIN_EMPRESA)
    },

    showTableCompanies() {
      return this.getMode === 'update' && this.getProfileKey === chavePerfil.ADMIN_EMPRESA
    },

    canByNationalBaseRule() {
      return this.$can(actions.VISUALIZAR, subjects.ATUACAO_BASE_NACIONAL)
    },
  },

  beforeDestroy() {
    this.$store.commit('adminUserState/clearFormData');
  },

  mounted() {
    this.$refs.formItems.reset()
  },

  methods: {
    async formSubmit(redirect) {
      this.redirect = redirect

      const resultFormItems = new Promise((resolve, reject) => {
        this.$refs.formItems.validate()
          .then(success => {
            success ? resolve(true) : resolve(false)
          })
          .catch(() => {
            reject()
          })
      })

      const resultProfileSettings = new Promise((resolve, reject) => {
        this.$refs.profileSettings.$refs.formProfileSettings.validate()
          .then(success => {
            success ? resolve(true) : resolve(false)
          })
          .catch(() => {
            reject()
          })
      })

      if (await resultFormItems && await resultProfileSettings) {
        if (this.getUoProfileMode && this.getFormData.unidades.length === 0) {
          await warningMessage('Ao menos uma unidade deve ser vinculada ao usuário.')

          return
        }
        if ((this.getProfileKey === chavePerfil.ADMIN_EMPRESA) && this.getFormData.empresas.length === 0) {
          await warningMessage('Não é possível editar o usuário sem algum vínculo com empresa(s).')

          return
        }

        if (this.hasProfileChange) {
          warningMessageChangeProfileCase2(
            this.getFormData.nome,
            this.getAdminUserUpdate.perfil[0].descricao,
            this.getFormData.perfil.descricao,
          )
            .then(() => this.core())
        } else {
          await this.core()
        }
      }
    },

    async core() {
      if (this.getMode === 'insert') {
        await this.handleCreate()
      }

      if (this.getMode === 'update') {
        await this.handleUpdate()
      }

      return true
    },

    async handleCreate() {
      this.loading = true

      const formData = this.formDataGenerate()
      
      await createAdminSesiUser(formData)
        .then(response => {
          if (response.status === 201) {
            this.clear()

            successMessage('O registro foi salvo com sucesso!')
          }
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.loading = false
    },

    async handleUpdate() {
      this.loading = true

      const { id } = this.getFormData

      const formData = this.formDataGenerate()

      await updateAdminSesiUser(id, formData)
        .then(response => {
          if (response.status === 200) {
            this.clear()

            successMessage('O registro foi alterado com sucesso!')
          }
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.loading = false
    },

    returnPessoa() {
      if(this.getMode === 'insert') {
        if (strClear(this.getFormData.cpf).length == 11) {
          this.$http
            .get(this.$api.getPessoaFisicaPorCpf(strClear(this.getFormData.cpf)))
            .then((data) => {
              const pessoa = data.data;
              if(pessoa != undefined && pessoa != '') {
                this.getFormData.nome = pessoa.nome;
                this.getFormData.sexo = pessoa.genero;
                this.getFormData.nascimento = moment(pessoa.data_nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY');
                this.formDisabled = true;

                this.modalCpf.showModal = true;
              }else{
                this.getFormData.nome = null;
                this.getFormData.sexo = null;
                this.getFormData.nascimento = '';
                this.formDisabled = false;
              }
            })
            .catch(error => {
              this.handleError(error.response)
            })
        }
      }
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        warningInfoMessage(
          response.data.errors,
          'Confira os dados e depois salve o usuário.',
          'Entendido',
        )

        return
      }

      warningMessage('Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.')
    },

    setCompanies(companies) {
      this.getFormData.empresas = companies
    },

    setLoading(loading) {
      this.loading = loading
    },

    closeModalCpf() {
      this.modalCpf.showModal = false;
    },

    formDataGenerate() {
      
      let unidades = []
      let empresas = []

      let tipoAtuacao = null

      let permiteEstornarVacinacao = null

      let tipoDocumento = null;
      let numeroDocumento = null;
      let estadoDocumento = null;

      if (this.canByNationalBaseRule) {
        tipoAtuacao = this.getFormData.tipoAtuacao ? this.getFormData.tipoAtuacao.id_tipo_atuacao : null
      }

      if (Array.isArray(this.getFormData.unidades)) {
        unidades = this.getFormData.unidades || []
      } else {
        unidades = [this.getFormData.unidades.id_unidade]
      }

      if (this.getFormData.empresas) {
        empresas = this.getFormData.empresas.length > 0 ? getArrayAttr(this.getFormData.empresas, 'id_empresa') : []
      }

      if (this.getFormData.vacinadorOption) {
        tipoDocumento = this.getFormData.tipoDocumento.id_tipo_documento;
        numeroDocumento = this.getFormData.numeroDocumento;
        estadoDocumento = this.getFormData.estadoDocumento.uf;
      }

      if(this.getFormData.permiteEstornarVacinacaoOption) {
        permiteEstornarVacinacao = this.getFormData.permiteEstornarVacinacaoOption;
      }else{
        permiteEstornarVacinacao = false;
      }

      return {
        nome: this.getFormData.nome,
        cpf: strClear(this.getFormData.cpf),
        dataNascimento: moment(this.getFormData.nascimento, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        sexo: ((typeof this.getFormData.sexo === 'string') ? this.getFormData.sexo : this.getFormData.sexo.name),
        email: this.getFormData.email,
        perfil: this.getFormData.perfil.id_perfil,
        departamento: this.getFormData.departamento.id_departamento,
        tipoAtuacao,
        empresas,
        unidades,
        tipoDocumento,
        numeroDocumento,
        estadoDocumento,
        permiteEstornarVacinacao
      }
    },

    redirectToList() {
      if (this.redirect) {
        this.$router.replace({
          name: 'usuario-list',
        })
      }
    },

    cancel() {
      if (this.getMode === 'insert') {
        this.redirect = true
        this.clear()
      } else {
        this.$router.replace({
          name: 'usuario-list',
        })
      }
    },

    clear() {
      this.$store.commit('adminUserState/clearFormData')

      this.$store.commit(
        'adminUserState/setAdminUserUpdate',
        null,
      )

      this.$refs.profileSettings.clear()

      this.$refs.formItems.reset()

      this.redirectToList()
    },
  },
}
</script>

<style lang="scss" scoped>
.p-card-form {
  padding: 3rem 2rem;
}

.button-custom-size {
  margin-right: 2rem;
}

@media (max-width: 705px) {
  .button-custom-size {
    margin-bottom: 1rem;
  }
}

@media (max-width: 400px) {
  .button-custom-size {
    width: 100%;
    margin-bottom: 1rem;
  }
}
</style>
